import Fuse, { type FuseOptionKey } from 'fuse.js'
import { useMemo } from 'react'

export function useFuseSearch<T>({
  query,
  list,
  keys,
}: {
  query: string
  list: T[]
  keys: FuseOptionKey<T>[]
}) {
  const fuse = useMemo(() => {
    return new Fuse(list, {
      keys,
      includeScore: true,
      threshold: 0.3,
      minMatchCharLength: 2,
    })
  }, [list, keys])

  return useMemo(() => {
    if (query.trim().length === 0) return list
    const searchResults = fuse.search(query)
    return searchResults.map((result) => result.item)
  }, [fuse, query, list])
}
